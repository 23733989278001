<template>
<div class='wrapper-skeleton'>  
<span>
    <div class="card-skeleton">
    <div class="card-info">
      <div class="info-skeleton small animation"/>
      <div class="info-skeleton big animation"/>
      <div class="info-skeleton small animation"/>
      <div class="info-skeleton big animation"/>
      <div class="info-skeleton small animation"/>
      <div class="info-skeleton big animation"/>
      <div class="info-skeleton small animation"/>
      <div class="info-skeleton big animation"/>
      <div class="info-skeleton small animation"/>
      <div class="info-skeleton big animation"/>
    </div>

  </div>
</span>
 <!-- <span>
 <div class="card-skeleton">
    <div class="card-info">
    <div class="info-skeleton small animation"/>
      <div class="info-skeleton big animation"/>
      <div class="info-skeleton small animation"/>
      <div class="info-skeleton big animation"/>
      <div class="info-skeleton small animation"/>
      <div class="info-skeleton big animation"/>
    </div>
  </div>
  <div class="card-skeleton">
    <div class="card-info">
    <div class="info-skeleton small animation"/>
      <div class="info-skeleton big animation"/>
      <div class="info-skeleton small animation"/>
      <div class="info-skeleton big animation"/>
      <div class="info-skeleton small animation"/>
      <div class="info-skeleton big animation"/>
    </div>
  </div>

 </span> -->
</div>
</template>
<script>
export default {
  data(){
    return{
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper-skeleton{
  display: flex;
  flex-direction: column;
  >span{
    display: flex;
  }
  margin: 0 auto;
  width: 900px;
  max-width: 80vw;
}

.card-skeleton{
  width: 100%;
  border-radius: 30px;
  background-color: white;
  padding: 1em;
}
.card-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-skeleton{
  width: 100%;
  height: 1.5em;
  margin-bottom: 1em;
  border-radius: .5em;    background-color: #ddd;


  &.small{
    width: 30%;
  }
  &.medium{
    width: 60%;
  }
}
.midia-skeleton{
  width: 100%;
  border-radius: .5em;
  height: 10em;
  margin-bottom: 1em;
  background-color: #ddd;
}

@keyframes skeleton-loading {
  0%{
    background-color: #ddd;
  }
  100%{
    background-color: #eee;
  }
}

.animation{
  -webkit-animation: skeleton-loading .5s infinite alternate;
  animation: skeleton-loading .5s infinite alternate;
}

//css indesejados
.v-application ul, .v-application ol {
    padding-left: unset;
}

</style>