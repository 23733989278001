import api from './api'

export default {
  cadastrarSistemasClientes(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/contrato/cadastraAtualizaSistemasCliente', body, config)
  },
  buscar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/contrato/consultaSistemasContratados', body, config)
  },
  deletar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/contrato/deletarSistemasContratados', body, config)
  }
}