<template>
  <v-container class="vcontainer">
    <div class="header">
      <span>
        <h3>Inserir um novo Cliente</h3>
        <p style="text-align: start;">Insira um novo cliente para gerir os processos de implantação.</p>
      </span>
      <div class="grow"/>
      <button type="button" class="btn-voltar" v-if="loading" @click="$router.push('/lista/cliente')">voltar</button>
    </div>
    <form class="formulario" autocomplete='off' v-if="loading">
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="nome"
            name="nome"
            label="Nome do cliente"
            :error-messages="erros.nome"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="cnpj"
            name="cnpj"
            label="Cnpj do cliente"
            :error-messages="erros.cnpj"
            v-mask="'##.###.###/####-##'"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>      
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="email1"
            name="email1"
            label="1º Email do cliente"
            :error-messages="erros.email1"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="telefone1"
            name="telefone1"
            label="1º Telefone do cliente"
            v-mask="'(##) # ####-####'"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>      
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="email2"
            name="email2"
            label="2º Email do cliente"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="telefone2"
            name="telefone2"
            label="2º Telefone do cliente"
            v-mask="'(##) # ####-####'"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="codigoProposta"
            name="codigoProposta"
            label="Código proposta"
            outlined
          ></v-text-field>
        </v-col>
          <v-col   cols="12"
          sm="6">
            <v-select hide-details  :items="tipointegracao" label="Tipo Integração" item-text="nome" item-value="value" v-model="tipointegracaoSelecionado" return-object outlined>
            </v-select>
        </v-col>
      </v-row> 
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-btn
            elevation="0"
            :ripple="false"
            class="btn"
            data-cy="btn-envio"
            color="#1377F2"
            type="submit"
            :disabled="reqloading"
            :loading="reqloading"
            @click="salvarCliente">
            criar cliente
          </v-btn>
        </v-col>
      </v-row>    
    </form>
    <form-skeleton v-if="!loading"/>
    <div class="containerAddSistemas" v-show="sucess">

      <div class="containerMessage" v-show="message">
        <h1>=)</h1>
        <p>Cliente cadastrado com sucesso</p>
        <p>Deseja continuar para o cadastro dos sistemas contratados pelo cliente?</p>
        <section class="containerButtonMessage">
          <button type="text" class="btnMessage" @click="continuarOuCancelarCadastroDeSistemas" name="continuar">Continuar</button>
          <button type="text" class="btnMessage" name="cancelar" 
          @click="continuarOuCancelarCadastroDeSistemas" 
          >Cancelar</button>
        </section>
      </div>

       <div v-show="formCadastroDeSistemas" class="formCadastroDeSistemas">
        <section> 
          <v-select
            class="select"
            :items="sistemas"
            label="Selecione um sistema"
            v-model="sistemaSelecionado"
          ></v-select>

        <v-btn
            class="btnAddSistemas"
            color="#1377F2"
            type="button"
            @click="adicionarSistema"
            >
            Adicionar Sistemas
          </v-btn>
        </section>
          <p class="titleSistemasCadastrados">Sistemas Adicionados:</p>
          <p class="titleNenhumSistema" v-show="sistemasCadastrados.length == 0">Nenhum sistema adicionado</p>
          <ul v-for="(sistemas, index) in sistemasCadastrados" :key="index">
            <li class="li"><span>{{sistemas}}</span><button type="button" class="buttonRemoverSistema" @click="removeSistema(sistemas)"><img src="/CriarCliente/iconeX.png" alt="img remove" /></button></li>
          </ul>
          <v-btn
            class="btnFormaCadSistemasClientes"
            color="#1377F2"
            type="button"
            @click="finalizarCadastroDeSistemasContratados"
            >
            {{ textBntCadastroSistema }}
          </v-btn>
       </div>
      <div v-show="notificacaoImplantacao" class="containerNotificacaoImplantacao">
        <h1>=)</h1>
        <h3>Sistemas cadastrados com sucesso!</h3>
        <p class="mensagemCriarImplantacao">Deseja criar implantações?</p>
        <div class="containerButtonNotificacaoImplant">
          <button type="button" class="btnMessage" @click="continuarCriarImplantacao($event)">Continuar</button>
          <button type="button" class="btnMessage" @click="continuarCriarImplantacao($event)">Não</button>
        </div>
      </div>
      <FormCriarImplantacao @emit-click="implantacaoConfirmar" v-show="formCriarImplantacao" :idCliente="id_cliente_cadastrado" :sistemasDosCliente="sistemasCliente" />
      </div>
      
      <v-dialog v-model="modalEnviarEmailContrato" width="500px" max-width="80vw">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column; padding: 10px;">
            <h2>Enviar contrato</h2>
            <section> 
          <v-select
            class="select"
            :items="contratos"
            item-text="titulo" 
            item-value="id"
            label="Selecione um contrato"
            v-model="contratoSelecionado"
          ></v-select>

          <div style="display: flex; justify-content: space-around;">
            <button class="btnMessage"  @click="enviarMensagem">Enviar</button>
            <button class="btnMessage" @click="() => this.modalEnviarEmailContrato = false">Cancelar</button>
          </div>
        </section>
          </div>
    </v-dialog>

    <v-dialog v-model="modalExibirLinkContrato" width="500px" max-width="80vw">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column; padding: 10px;">
            <section> 
              <div style="width: 100%; display: flex;  flex-direction: column; justify-content: center; align-items: center;">
                <h2>Contrato enviado com sucesso!</h2>
                <a :href="linkContrato" target="_blank" style="margin: 10px 0 10px 0; font-size: larger; font-size: 20px;">Contrato</a>
              </div>
          <div style="display: flex; justify-content: space-around;">
            <button class="btnMessage" @click="() => this.modalExibirLinkContrato = false">Ok</button>
          </div>
        </section>
          </div>
    </v-dialog>
    </v-container>

</template>

<script>
import {alertar} from '@/services/notificacao.service.js';
import ServicoCliente from '@/services/cliente.service.js';
import SistemasClientes from '@/services/sistemasCliente.service.js';
import validarCnpj from '@/util/validarCnpj.js';
import FormSkeleton from '@/components/Loading/FormSkeleton.vue';
import SistemaServico from '@/services/sistema.service.js';
import FormCriarImplantacao from './FormCriarImplantacao.vue';
import termo_de_adesao from '../../services/termo_de_adesao.service';
import ImplantacaoServico from '@/services/implantacao.service.js';


export default {
  components: { FormSkeleton, FormCriarImplantacao },
  data(){
    return {
      loading: false,
      sistemaSelecionado: null,
      id_sistema: null,
      id_cliente: null,
      id_cliente_cadastrado: null,
      notificacaoImplantacao: false,
      formCriarImplantacao: false,
      sistemas: [],
      sucess: false,
      message: false,
      formCadastroDeSistemas: false,
      sistemasCadastrados: [],
      sistemasCliente: [],
      nome: null,
      cnpj: null,
      email1: null,
      telefone1: null,
      email2: null,
      telefone2: null,
      reqloading: false,
      modalEnviarEmailContrato: false,
      emailEnvioContrato: null,
      payLoadFormCriarImplantacao: null,
      erros: {
        nome: '',
        cnpj: '',
        email1: '',
        telefone1: '',
        email2: '',
        telefone2: '',
        codigoProposta: '',
      },
      tipointegracao:[
        {nome:'Totvs Oracle',valor:1},
        {nome:'Totvs Sql Server',valor:2},
        {nome:'Hits',valor:3},
        {nome:'ERBON',valor:4},
        {nome:'ADMH',valor:5},
        {nome:'NEW HOTEL',valor:6},
      ],
      tipointegracaoSelecionado:{nome:'Hits',valor:3},
      contratos: null,
      contratoSelecionado: null,
      textBntCadastroSistema: 'Cadastrar',
      modalExibirLinkContrato: false,
      linkContrato: null,
      codigoProposta: null,
    }
  },
  methods: {


    async enviarMensagem() {
      const body = {
        idcliente: this.id_cliente_cadastrado,
        idcontrato: this.contratoSelecionado,
        email: this.emailEnvioContrato
      }
      await termo_de_adesao.enviarLinkContrato(body)
      .then((res) => {
        this.modalEnviarEmailContrato = false;
        this.linkContrato = `https://es.economysoftware.com.br/formulario/${this.id_cliente_cadastrado}/${this.contratoSelecionado}`
        this.modalExibirLinkContrato = true;
        console.log(res.data)
      })
      .catch((error) => {
        console.log(error);
      })
    },

    removeSistema(sistema) {
      this.sistemasCadastrados = this.sistemasCadastrados.filter((e) => e !== sistema);
    },

    async salvarCliente(e){
      e.preventDefault()
      if(this.validacao()){
        this.reqloading = true
        let cliente = {
          cnpj: this.cnpj,
          nome: this.nome,
          telefonecontato1: this.telefone1,
          telefonecontato2: this.telefone2,
          contato1: this.email1,
          contato2: this.email2,
          codigoProposta: this.codigoProposta,
          id_tipo_integracao: Number(this.tipointegracaoSelecionado.valor)
        }
        this.emailEnvioContrato = this.email1;
        await ServicoCliente.criar(cliente).then(res =>{
          if(res.status === 200){
            const { ids } = res.data;
            this.id_cliente_cadastrado = ids[0];
            this.sucess = true;
            // this.message = true;
            this.formCadastroDeSistemas = true;
            this.reqloading = false
            this.nome = null
            this.email2 = null
            this.email1 = null
            this.telefone2 = null
            this.telefone1 = null
            this.cnpj = null
          }
        }).catch(e =>{
          this.reqloading = false
            alertar('warning', '=/', 'Falha na crição do usuário!')
          console.log('e', e)
        })
      }
    },

    async criarImplantacao(data) {

        console.log('criarImplantacao ',data)

        const dadosimplantacao = {
            idsistema: data[0].id_sistema,
            idcliente: data[0].id_cliente,
            urlsite: "xpto.com",
            urlapi: "xapi.com",
            idfluxo: null,
            datainicio: null,
            dataconclusaoestimada: null,
            dataconclusao: null,
            concluida: false
        }

        await ImplantacaoServico.criar(dadosimplantacao)
            .then(async () => {
                       alertar('success', '=)', 'Implantação criada com sucesso!')
            })
            .catch(e => {
              alertar('warning', '=/', 'Falha na criação da implantação')
              console.log('error na criacao de implantação', e)
            })
    },

    implantacaoConfirmar() {
      this.sucess = false,
      this.formCriarImplantacao = false;
      this.message = false;
      this.modalEnviarEmailContrato = true;
    },

    async continuarCriarImplantacao(event) {
      const { target } = event;
      const { innerText } = target;

      if(innerText === 'Continuar') {
        await this.requisitarSistemasDoCliente()
        this.notificacaoImplantacao = false;
        this.formCriarImplantacao = false;
      } else {
        this.notificacaoImplantacao = false;
        this.sucess = false;
        this.modalEnviarEmailContrato = true;
      }
 

    },

    async requisitarSistemasDoCliente(){
          const buscaSistemas = await SistemasClientes.buscar({id: this.id_cliente_cadastrado}).then(res=> res)
          console.log(buscaSistemas.data)
          this.sistemasCliente = buscaSistemas.data;
          return buscaSistemas.data;
        },  
  
    async finalizarCadastroDeSistemasContratados() {
      this.textBntCadastroSistema = 'Aguarde...'
      
      const sistemas = await this.requisitarSistemas()
      
      let sistemasFilter = [];
      sistemas.forEach((e, i) => {
        sistemas.forEach((e2) => {
          if (this.sistemasCadastrados[i] === e2.nome) {
            sistemasFilter = [...sistemasFilter, e2]
          }
        })
      })
      let data = []

      console.log("sistemasFilter ",sistemasFilter)

      // sistemasFilter.forEach(async (e) => {
      //   // await this.apiPostSistemaCliente(sistema.id)
      //   // data = [...data, {
      //   //   id_cliente: this.id_cliente_cadastrado,
      //   //   id_sistema: e.id
      //   // }]
      //   data = [{
      //     id_cliente: this.id_cliente_cadastrado,
      //     id_sistema: e.id
      //   }]
      //   console.log("data ",data)
      //   await this.apiPostSistemaCliente(data)
      //   await this.criarImplantacao(data)
      // })
      for(let i=0,leni=sistemasFilter.length; i < leni; i++){
        let e = sistemasFilter[i]
        
        data = [{
          id_cliente: this.id_cliente_cadastrado,
          id_sistema: e.id
        }]
        await this.apiPostSistemaCliente(data)
        await this.criarImplantacao(data)
      }
      console.log(data);
      this.implantacaoConfirmar()
      // this.apiPostSistemaCliente(data)
    },

    async apiPostSistemaCliente(data) {

      await SistemasClientes.cadastrarSistemasClientes(data).then(async (res) =>{
          if(res.status === 200){
            await this.requisitarSistemasDoCliente()
            this.formCriarImplantacao = false;
            this.formCadastroDeSistemas = false;
            this.sistemasCadastrados = [];
            // this.implantacaoConfirmar()
          }
        }).catch(e =>{
          this.reqloading = false
            alertar('warning', '=/', 'Falha na crição do cadastro de sistemas!')
          console.log('e', e)
        })
    },

    validacao(){
      let validado = []
      let objretorno;
      if(!this.nome || this.nome.trim() === ''){
        validado.push(false)
        this.erros.nome = 'O campo nome é obrigatório.'
      }
      if(!this.cnpj || this.cnpj.trim() === ''){
        validado.push(false)
        this.erros.cnpj = 'O campo cnpj é obrigatório.'
      }else{
        let cnpjvalido = validarCnpj(this.cnpj)
        validado.push(cnpjvalido)
        if(!cnpjvalido){
          this.erros.cnpj = 'Insira um cnpj válido.'
        }
      }
      if(!this.email1 || this.email1.trim() === ''){
        validado.push(false)
        this.erros.email1 = 'O campo email é obrigatório.'
      }

      // if(!this.codigoProposta || this.codigoProposta.trim() === ''){
      //   validado.push(false)
      //   this.erros.codigoProposta = 'O campo Código da proposta é obrigatório.'
      // }
      
      validado.includes(false) ? objretorno = false : objretorno = true
      return objretorno
    },
    selecionarcampo(e){
      if(this.erros[e.target.getAttribute('name')]){
        this.erros[e.target.getAttribute('name')] = ''
      }
    },

    adicionarSistema() {

      const verificacaoSistemasIguais = this.sistemasCadastrados.some((e) => e === this.sistemaSelecionado)
      if(verificacaoSistemasIguais) {
        return alertar('basic', 'Atenção!', 'Esse sistema já foi adicionado.')
      }

      if (this.sistemaSelecionado === null) return global.alert("Seleciona um sistema")

      this.sistemasCadastrados = [...this.sistemasCadastrados, this.sistemaSelecionado]

    },
    

    continuarOuCancelarCadastroDeSistemas(e) {
      const btnClicado = e.target.innerText
      if (btnClicado === 'Cancelar') {
        this.sucess = false;
      } else {
        this.message = false;
        this.formCadastroDeSistemas = true;
      }
    },
    async requisitarSistemas(){
      const buscaSistemas = await SistemaServico.buscar().then(res=> res.data)

      buscaSistemas.forEach((sistema) => {
        this.sistemas = [...this.sistemas, sistema.nome];
      });
      return buscaSistemas;
    },

    cancelarCadastroDeSoftware() {
      this.sucess = false;
      this.formCadastroDeSistemas= false;
      this.sistemasCadastrados = [];
    },

    async requisitarContrato() {
      await termo_de_adesao.buscarTodosOsContratos()
      .then((res) => {
        console.log(res.data)
        this.contratos = res.data
      })
      .catch((error) => {
        console.log(error)
      })
    }

  },
  async mounted(){
    // this.nome = "HNB 15012024"
    // this.cnpj = "59.321.037/0001-64"
    // this.email1 = "herlessi@gmail.com"
    // this.telefone1 = "85987875487"
    // this.codigoProposta = "123"
    await this.requisitarSistemas();
    await this.requisitarContrato();
    this.loading = true
  }
}
</script>

<style lang="scss" scoped>

.vcontainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}
.header{
  display: flex;
  padding: 1em;
  margin: 0 auto;
  width: 900px;
  max-width: 80vw;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
   background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.btn-voltar{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
  }

.btn{
  background-color: #1377F2;
  width: 100%;
  height: 3em !important;
  color: whitesmoke;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 1em;
  border-radius: 10px;
  font-weight: bold;
}
.formulario{
  margin: 0 auto 5em auto;
  width: 900px;
  max-width: 80vw;
  box-shadow:  4px 4px #d9d9d9;
  padding: 5em 1em 3em 1em;
  border-radius: 30px;
  background-color: #f9f9f2;
}

.theme--light.v-messages {
    color: red !important;
}

//css indesejados
.v-input, .v-text-field{
  outline: none !important;
}
.v-text-field__details{
  display: none !important;
}

.col-sm-6, .col-12{
  padding: 0 12px !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    min-width: unset !important;
    padding: unset !important;
  }

.containerAddSistemas {
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  width: 100vw;
  height: 100vh;
  background-color: #080808b8;

  .select {
  width: 70%  !important;
  margin-right: 10px;
  color: #808080;
}
.btnAddSistemas {
  color:white;
  width: 200px;
}

 section {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  padding: 5px;
 }

 .titleSistemasCadastrados {
  font-size: 20px;
 }

}
.containerMessage {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  width: 500px;
  height: 200px;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  -webkit-animation: scale-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
	animation: scale-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.btnMessage {
  color: white;
  background-color: #1377F2;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  width: 100px;
}

.containerButtonMessage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formCadastroDeSistemas {
  background-color: #f9f9f2;
  width: 700px;
  padding: 25px;
  border-radius: 8px;
  max-width: 80%;
  -webkit-animation: scale-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
	animation: scale-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.btnFormaCadSistemasClientes {
  margin-right: 10px;
  color: white;
  margin-top: 30px;
  width: 200px;
}

.titleNenhumSistema {
  font-size: 15px;
  margin-left: 20px;
}

.buttonRemoverSistema {
  background: none;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  img {
    width: 15px;
  }
}
.li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 145px;

  }

  @keyframes scale-in {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.containerNotificacaoImplantacao {
  background-color: white;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  width: 500px;
  display: flex;
  flex-direction: column;
  -webkit-animation: scale-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
	animation: scale-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.mensagemCriarImplantacao {
  margin-top: 10px;
}

.containerButtonNotificacaoImplant {
  margin-top: 20px;
}

</style>