<template>
    <CriarCliente />
</template>

<script>
import CriarCliente from '@/components/Cliente/CriarCliente'
export default {
  components: {CriarCliente},
  data(){
    return{}
  }
}
</script>

<style>

</style>