<template>
  <form>
    <h1>Implantação</h1>
    <p>{{sistemasDosCliente.length > 0 && sistemasDosCliente[this.index].nome_sistema}}</p>
    <v-select
     class="select"
     :items="fluxos"
     label="Selecione o fluxo"
     v-model="fluxo"
    ></v-select>
    <button class="button" type="button" @click="criarImplantacao($event)">Criar implantação</button>
    <p>{{1 + this.index }}/{{this.sistemasDosCliente.length}}</p>
  </form>
</template>

<script>
  import StakeHServico from '@/services/stakeholder.service.js';
  import {alertar} from '@/services/notificacao.service.js';
  import FluxoServico from '@/services/fluxo.service.js';
  import ClienteServico from '@/services/cliente.service.js';
  import sistemasCliente from '@/services/sistemasCliente.service.js';
  import ImplantacaoServico from '@/services/implantacao.service.js';

  export default {
    props: ['idCliente', 'sistemasDosCliente'],
    name: 'FormCriarImplantacao',
    data() {
      return {
        clientes: null,
        index: 0,
        clientesdb: null,
        stakeholders: [],
        stakeholdersdb: [],
        fluxo: [],
        fluxos: [],
        fluxosdb: [],
        sistemasCliente: [],
        idStake: null,
      }
    },
    methods: {
      async requisitarFluxos(){
      this.fluxos = await FluxoServico.buscar().then(res => {
        this.fluxosdb = res.data
        return res.data.map(fluxo => fluxo.nome)
      })
    },
  
    async requisitarStakeholders(){
      this.stakeholders = await StakeHServico.buscar().then(res => {
        this.stakeholdersdb = res.data
        return res.data.map(stake => stake.nome)
      })
    },

    async criarImplantacao(event){
      const { target } = event;
      const { innerText } = target; 

      if(innerText === 'Criar implantação') {
        // Criar implantação default
      // const cliente = await this.requisitarClientes();

      // criar stakeHolder
      // const dadosusuario = {
      //     principal: true,
      //     nome: cliente[0].nome,
      //     telefone: cliente[0].telefonecontato1,
      //     email: cliente[0].contato1,
      //     idimplantacao:null,
      //     idambiente: 2,
      //     idtipo: 4,
      //     idcliente: this.idCliente
      //   }


      //  await this.criarStakeHolder(dadosusuario);
        //finaliza implantacao
          const dadosimplantacao = {
          idsistema: this.sistemasDosCliente[this.index].id_sistema,
          idcliente: this.idCliente,
          urlsite: "xpto.com",
          urlapi: "xapi.com",
          idfluxo: this.fluxosdb.filter(el => el.nome == this.fluxo)[0].id,
          datainicio: null,
          dataconclusaoestimada: null,
          dataconclusao: null,
          concluida: false
        }

        await this.postImplantacao(dadosimplantacao);
        if (this.index < this.sistemasDosCliente.length - 1) {
          this.index = this.index + 1;
        } else {
          this.index = 0;
          this.$emit('emit-click', event)
        }
      } else {
        this.$emit('emit-click', event)
      }
    },

    async criarStakeHolder(dadosusuario) {
      await StakeHServico.criar(dadosusuario).then(res => {
          if(res.status === 200){
            if(this.retornar && this.retornar === true){
              this.$emit('input', res.data)
            }
            this.idStake = res.data;
            console.log(this.idStake.ids[0]);
          }
        }).catch(e => {
          console.log('error criarcao stakeholder', e)
          alertar('warning', '=/', 'Falha na criação do StakeHolder')
        })
        this.reqloading = false
      },

      async postImplantacao(dadosimplantacao) {
        await ImplantacaoServico.criar(dadosimplantacao)
            .then(async () => {
                  // const idstake = this.idStake.ids[0];
                  // const idusuario =  this.idCliente;
                    // await StakeHServico.criar({id: parseInt(idstake), idimplantacao: res.data.inserted[0],idusuario: parseInt(idusuario), })
                    // .then(() => {
                    //   console.log('sucesso vincular stakeholder')
                   
                    // })
                    // .catch(() => {
                    //   console.log('erro em vincular stakeholder')
                    // })
                       alertar('success', '=)', 'Implantação criada com sucesso!')
            })
            .catch(e => {
              alertar('warning', '=/', 'Falha na criação da implantação')
              console.log('error na criacao de implantação', e)
            })
        },

        async requisitarClientes(){
          this.clientes = await ClienteServico.buscar({id: this.idCliente}).then(res => {
            this.clientesdb = res.data
            // return res.data.map(item => ({nome: item.nome, value: item.id}))
            return res.data.map(item => item.nome)
          })
          return this.clientesdb;
        },

        async requisitarSistemasDoCliente(){
          const buscaSistemas = await sistemasCliente.buscar({id: this.idCliente}).then(res=> res)
          console.log(buscaSistemas.data)
          this.sistemasCliente = buscaSistemas.data;
          return buscaSistemas.data;
        },  
    },

    async mounted(){
    await this.requisitarFluxos()
    await this.requisitarStakeholders()
  }
  }
</script>

<style scoped>
  form {
    text-align: center;
    padding: 15px;
    border-radius: 10px;
    width: 500px;
    background-color: white;
    margin-top: 20px;
    -webkit-animation: scale-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
	animation: scale-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  }
  
  @keyframes scale-in {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


  .button {
    background-color: #1377f2;
    color: white;
    border-radius: 5px;
    padding: 5px;
    margin: 6px;
    width: 200px;
  }

</style>